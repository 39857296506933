import React from "react";
import "./homeComponent.css";

function HomeComponent() {
  return (
    <div className="home-body">
      <h1>Welcome to the Deutsche Carneval Verein of Pretoria!</h1>
    </div>
  );
}

export default HomeComponent;
